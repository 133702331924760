.ServiceMainSection{
    /* background-color: black; */
    /*background-color: #637A9F; */ 
    /* background-color: #378CE7; */
    background-color: #265073;
    height: 100vh;
}
.ServiceTitle{
    text-align: center;
    color: white;
    margin-bottom: 3rem;
}
.ServicesSection{
        font-size: 20px;
        font-family: 'Oswald', sans-serif;
        color: white;
}
.ServicesSection ul,.ServicesSection ul li{
        padding-left: 1rem !important;
}
@media screen and (max-width: 576px) {
    .ServiceMainSection{
        height: 100%;
    }   
}