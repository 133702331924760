.navbar{
    background-color: #102C57;
    padding: 20px 0px;
    /* border-bottom : 2px solid #C9D7DD; */
}
.navbar-toggler{
    color: black !important;
    background-color: white !important;
}
.nav-link{
    color: white !important;
    text-decoration: none !important;
    font-family: 'Oswald', sans-serif !important;
    font-size: 20px !important;
    font-weight: bold !important;
    padding: 0.5rem 1.3rem !important;
    padding-left: 0rem !important;
}

.navContainer{
    margin : 0px 0px 0px 100px;
  
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .nav-link{
        /* font-size: 16px; */
        font-size: 14px !important;
        font-weight: bold;
        padding: 0.5rem 0.6rem !important;
        padding-left: 0rem !important;
    }
}

@media (max-width: 767px) {
    .container {
      max-width: 93% !important;
    }
  }