.AssociateTitle{
    text-align: center;
    color: white;
    margin-bottom: 4rem;
}
.AssociatesClass{
padding-top: 100px;
/* background-color: #000000; */
/*background-color: #637A9F; */ 
/* background-color: #378CE7; */
background-color: #265073;
}
.CardImage{
    margin: 0 auto; 
    float: none; 
    margin-bottom: 10px; 
    width: 30% !important;
}
.CardImage1{
    margin: 20px auto;
}

.CardImage2{
    margin: 25px auto;
}
.CardSection{
    height: 25em;
}
.CardSection:hover{
    box-shadow: 0px 0px 22px 1px #f9fafb;
}
.displayCard{
    /* display: none; */
}

@media screen and (min-width: 0px) and (max-width: 413px) {
    .CardSection{
        height: 25em;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .CardSection{
        height: 25em;
    }
}