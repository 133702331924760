.BlogsClass{
    /* padding-top: 100px; */
    background-color: #000000;
    padding-top: 8rem;
    padding-bottom: 8.5rem;
    /* height: 100vh; */
    }
    .BlogsTitle{
        text-align: center;
        color: white;
        margin-bottom: 4rem;
    }
.pilotHeading {
    position: relative;
    width: 100%;
    /* margin-top: -5rem; */
}

.pilotHeading img {
    height: 310px;
    width: 100%;
    object-fit: cover;
}

.pilotHeading figcaption {
    color: var(--blueColor);
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
    /* font-size: 2.7rem; */
    font-size: 2.2rem;
    font-weight: 600;
}

.ImageContainer {
    position: relative;
}

.ImageContainer span {
    /* background: rgba(51, 91, 138, 0.9); */
    background: #505153f5;
    display: inline-block;
    padding: 10px;
    position: absolute;
    width: 100%;
    font-size: 1.2rem;
    bottom: 0;
    left: 0;
    color: white;
}

.tableOfContent_list {
    font-size: 1.1rem;
    color: var(--blueColor);
    padding: 3px 0;
}

.tableOfContent {
    position: sticky;
    top: 15%;
}

.publish {
    color: var(--blueColor);
}

.width50 {
    width: 65%;
}

.width30 {
    width: 32%;
}

.width40 {
    width: 45%;
}

.width25 {
    width: 52%;
}
.backIcon{
    cursor: pointer;
}
.backIcon svg{
    position: absolute;
    top: 18%;
    background-color: var(--blueColor);
    padding: 10px;
    border-radius: 50%;
    color: white;
    font-size: 3rem;
    left: 6%;
    cursor: pointer;
    z-index: 10;
}

@media (max-width: 1180px) {
    .width50 {
        width: 98%;
        height: 50%;
    }
    .backIcon svg{
        top: 9%;
    }
    .width30 {
        width: 98%;
    }

    .width40 {
        width: 98%;
    }

    .width25 {
        width: 98%;
    }

    .pilotHeading img {
        height: 240px;

    }

    .pilotHeading figcaption {
        top: 50%;
        font-size: 2.2rem;
    }

    .tableOfContent {
        position: static;
    }

    .SingleBlog_head figcaption {
        font-size: 1.4rem;
    }
}

@media (max-width: 992px) {
    .blogcard_common a img {
        height: 35vh;
    }
    
}
@media (max-width: 568px) {
.backIcon svg{
    display: none;
}
.width50 {
    width: 96%;
    height: 50%;
}
.backIcon svg{
    top: 9%;
}
.width30 {
    width: 96%;
}

.width40 {
    width: 96%;
}

.width25 {
    width: 96%;
}

}

.single_blog_content{
    color: white;
}