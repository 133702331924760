.AboutRow{
    position: relative;
}

.AboutSection{
    /* padding-top: 5rem; */
    padding-top: 8rem;
    /* background-color: black; */
    /*background-color: #637A9F; */ 
    /* background-color: #378CE7; */
    background-color: #265073;
    
}
.AboutTitle2{    
    font-size: 30px !important;
    margin-bottom: 10px;
}
.AboutTitle2,.AboutSecondSectionTitle,.AboutThirdSectionTitle{
    font-size: 24px;
    font-weight: bold;
    font-family: 'Oswald', sans-serif;
    color: white;
}
.AboutSecondSectionTitle,.AboutThirdSectionTitle{
    margin: 20px 0px 5px 0px;
}
.AboutFirstSection,.AboutSecondSection,.AboutThirdSection{
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
    color: white;
    line-height: 26px;
}
.AboutSecondSection ul,.AboutSecondSection ul li,.AboutThirdSection ol,.AboutThirdSection ol li{
    padding-left: 1rem !important;
}

.AboutAwardsTitle{
    font-size: 30px;
    font-weight: bold;
    font-family: 'Oswald', sans-serif;
    color: white;
    text-align: center;
    margin-top: 32px;
}
.AboutAwardsCarousel{
    padding-top: 55px;
    padding-bottom: 55px;
}
.slick-dots{
    bottom: -55px;
}
.slick-dots li button:before {
    color:White !important;
}
.AboutAwardsButton{
    text-align: center;
    padding: 40px 0px;
}
.CarouselDiv{
    text-align: center;
}

.CarouselDiv img{
    margin: auto;
}

.CarouselDiv img:hover{
    transform: scale(1.1);
}
.AboutButton{
    color: #f8f9fa; 
    /* background-color: #000; */
    /*background-color: #637A9F; */ 
    /* background-color: #378CE7; */
    background-color: #265073;
    border-color: #f8f9fa;
    font-size: 20px;
    font-weight: bold;
}

.AboutButton:hover{
    color: #000;
    background-color: #f9fafb;
}
.AboutContainer{
    display: flex; 
}
.AboutImage{
    margin: 0px 6rem 0 2rem;
    align-self: center;
    border: 5px solid white;
}

.AboutText{
    flex: 1 1 auto; flex: 1 1 auto;
}
.AboutTitle1{
    display: none;
}
@media screen and (min-width: 1024px){
    .AboutImage:hover {
        transform: scale(1.2);
      }
}
@media screen and (min-width: 0px) and (max-width: 1024px){
    .AboutTitle1{
        display: block;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Oswald', sans-serif;
        color: white;
        font-size: 30px !important;
        margin-bottom: 10px;
        text-align: center;    
    }
    .AboutTitle2{
        display: none;
    }
    .AboutContainer{
        display: block; 
    }
    .AboutText{
        margin-top: 30px;
    }
    .AboutImage {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 400px;
        object-fit: cover;
    }
}
@media screen and (min-width: 0px) and (max-width: 480px){
    .AboutImage {
        width: 300px;
        height: 400px;
        object-fit: cover;
        
    }
}