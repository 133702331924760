.BlogsTitle{
    text-align: center;
    color: white;
    margin-bottom: 4rem;
}
.BlogsClass{
/* padding-top: 100px; */
/* background-color: #000000; */
/*background-color: #637A9F; */ 
/* background-color: #378CE7; */
background-color: #265073;
padding-top: 8rem;
padding-bottom: 8.5rem;
/* height: 100vh; */
}
.BlogsColumn{
    margin: 0px auto;
}
.BlogCardSection:hover{
    box-shadow: 0px 0px 22px 1px #f9fafb;
}
.BlogCardImage{
    margin: 0 auto; 
    float: none; 
    margin-bottom: 10px; 
    /* width: 30% !important; */
}
.BlogCardText{
    font-size: 19px;
    font-weight: bold;
    text-align: center;
}   
.BlogCardImage1{
    margin: 20px auto 10px auto;
    /* border: 2px solid black;
    border-radius: 2px; */
    }

    .BlogButton{

    /* background-color: white;
    border: 2px solid white; */
    /* display: inline-block; */
    background-color: #588a8e;
    padding: 5px;
    /* width: 200px; */
    color: #ffffff;
    text-align: center;
    border: 4px double #cccccc;
    border-radius: 10px;
    font-size: 18px;
    }
    .BlogCardSection{
        border: none;
    }

    .BlogsContent{
        /* background-color: black; */
        background-color: #265073;
        /* margin-top: 9%;
        padding: 2% 0%; */
        padding-top: 12rem;
        
    }
    .BlogImage{
        width: 100%;
        object-fit: cover;
        height: 400px;
        opacity: 0.4;
    }     
                     
    .image {
        position: relative;
        border: 5px solid white;
        }
    .image figcaption{
        position: absolute;
        top: 43%;
        bottom: 50%;
        /* left: 12%; */
        font-size: 30px;
        color: #ffffff;
        text-align: center;
        font-weight: bold;
    }
    @media screen and (min-width: 0px) and (max-width: 414px) {
        .BlogsContent{
            padding: 24% 0%;
        }
        .BlogImage{
            height: auto;
        }
        .image figcaption{
            top: 34%;
            bottom: 0%; 
            left: 0%;
            font-size: 16px;
        }
    }
    @media screen and (min-width: 415px) and (max-width: 768px) {
        .BlogsContent{
            padding: 13% 0%;
        }
        .BlogImage{
            height: auto;
        }
        .image figcaption{
            top: 34%;
            bottom: 0%; 
            left: 0%;
        }
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        .BlogsContent{
            padding: 8% 0%;
        }
        .image figcaption{
            top: 38%;
            left: 0%;
        }
    }