.AwardsSection {
    /* padding-top: 100px; */
    padding-top: 8rem;
    /* background-color: #000000; */
    /*background-color: #637A9F; */ 
    /* background-color: #378CE7; */
    background-color: #265073;
}
.AwardsTitle{
    text-align: center;
    color: white;
    margin-bottom: 4rem;
}
.AwardsRow img{
    border: 5px solid #ffffff;
}
.mainContainer{
    height: 380px;
}
.theCard{
    position: relative;
  width: 100%;
  height: 100%;
  transition: transform 1.6s;
  transform-style: preserve-3d;
}
.mainContainer:hover .theCard{
    transform: rotateY(180deg);
}
.frontSide, .backSide{
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.frontSide{
    background-color: #bbb;
    color: black;
}
  
.backSide{
    /* background-color: #000000; */
    background-color: #102C57;
    color: #ffffff;
    border: 5px solid #ffffff;
    transform: rotateY(180deg);
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }