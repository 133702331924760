.ContactTitle{
    font-family: 'Oswald', sans-serif;
    text-align: center;
    color: white;
    margin-bottom: 1rem;
}
.contactClass{
    /* background-color: black; */
    /*background-color: #637A9F; */ 
    /* background-color: #378CE7; */
    background-color: #265073;
    /* padding-top: 100px; */
    padding-top: 8rem;
    height: 100vh;
}

label{
    display: inline-block;
    width: 9%;
    text-align: right;
    margin: 20px;
    color: white;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    font-weight: bold;
}
.inputClass{
    max-width: 350px;
    background-color: #ffffff;
    width: 100%;
    height: 38px;
    border: none;
    margin-bottom: 10px;
}
.inputTextClass{
    max-width: 350px;
    background-color: #ffffff;
    width: 100%;
    height: 8rem;
    border: none;
    margin-top: 10px;
}

.SubmitClass{
    display: block;
    height: 38px;
    border: none;
    margin: 10px 0px;
    border-radius: 5px;
    font-family: 'Oswald', sans-serif;
    font-size: 18px;
    font-weight: bold;
}

.formClass{
    text-align: -webkit-center;
}
.ContactDetailsClass{
    display: flex;
}

.phoneDetails{
    display: none;
}
.phoneIcon:hover .phoneDetails{ 
    display: block;
}

.ContactDetailsClass .phoneIcon, .ContactDetailsClass .phoneDetails{
    color: #ffffff;
}
.ContactDetailsClass .phoneIcon{
    margin: 0px 20px;
    cursor: pointer;
}
.LinkedInIcon,.FacebookIcon,.TwitterIcon{
    color: #ffffff;
    margin: 0px 20px;
    cursor: pointer;
}
.labelClassMessage{
    margin-bottom: 0px;
}
@media screen and (min-width: 0px) and (max-width: 767px) {
    .labelClass{
        display: flex;
        margin: 0px;
    }
    .formClass {
        text-align: start;
    }
    .formClass {
        text-align: start;
    }
    .inputClass{
        width: 90%;
        max-width: none;
    }
    .labelClassMessage{
        margin: 0px;
        display: flex;
    }
    .contactClass{
        height: 100%;
    }
    .inputTextClass{
        width: 90%;
        max-width: inherit;
    }
    .ContactDetailsClass{
        padding-bottom: 4rem;
    }
    .ContactDetailsClass{
        display: block;
    }
    .LinkedInIcon, .FacebookIcon, .TwitterIcon {
        margin: 10px 20px;
        width: 10%;
    }
    .phoneIcon{
        width: 52%;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .inputClass{
        margin-left: 30px;
    }
    .inputTextClass{
        margin-left: 30px;
    }
}

.popup-content {
    margin: auto;
    background: rgb(5, 5, 5);
    width: 50%;
    padding: 5px;
  }
  .popup-arrow {
    color: rgb(9, 8, 8);
  }
  [role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  }
  
  .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  [data-popup='tooltip'].popup-overlay {
    background: transparent;
  }
  .PopupClass{
    display: block;
    background: white;
    font-size: 20px;
    font-weight: bold;
  }

  @media screen and (min-width:1024px) {
    .contactClass{
        height: 100vh;
    }
  }