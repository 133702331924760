.MainContainer{
    background-color: #265073;
    height: 100%;
    /* background-image: url('../../../public/image/Pro2-min.jpg'); */
    /* background-image: url('../../../public/image/rajeev_khade_bg-removebg-preview.png'); */
    background-image: url('../../../public/image/Pro2-min-removebg-preview.png');
    background-position: right;
    /* background-size: cover; */
    background-size: contain;
    background-repeat: no-repeat;
    /* background-position: 346px 4%; */
    /* background-position: 802px; */
    margin-top: 3rem;
    /* margin-top: 4rem; */
}
.MainTitle{
    margin-left: 6%;
    color: white;
    text-decoration: none;
    font-family: 'Oswald', sans-serif;
    font-size: 32px;
    /* padding-top: 21%; */
    padding-top: 19%;
}

.MainDescription{
    margin-left: 6%;
    color: white;
    text-decoration: none;
    font-family: 'Oswald', sans-serif;
    font-size: 46px;
    font-weight: bold;
    padding-top: 1%;
    width: 50%;
}

.MainSubDescription{
    margin-left: 6%;
    color: white;
    text-decoration: none;
    font-family: 'Oswald', sans-serif;
    font-size: 22px;
    padding-top: 2%;
    width: 50%;
    line-height: 30px;
}
#responsive-navbar-nav{
    /* padding-top: 1%; */
}

body::-webkit-scrollbar {
    display: none;
}
@media screen and (min-width: 0px) and (max-width: 413px) {
    .MainContainer{
        background-position: 6px 8%;
        height: 100%;
        margin-top: 4rem;
    }
    .MainTitle{
        /* padding-top: 26%;
        padding-top: 40%; */

        padding-top: 125%;
        /* font-size: 21px; */
        
    }
    .MainDescription{
        font-size: 21px;
        width: 95%;
    }
    .MainSubDescription{
        /* width: 36%; */
        width: 95%;
        font-size: 19px;
    }
}
@media screen and (min-width: 414px) and (max-width: 767px) {

    .MainContainer{
        background-position: 64px 8%;
        height: 100vh;
    }
    .MainTitle{
        padding-top: 23%;
        font-size: 21px;
        
    }
    .MainDescription{
        font-size: 21px;
        width: 42%;
    }
    .MainSubDescription{
        width: 36%;
        font-size: 19px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .InnerContainer{
        padding-top: 100%;
        background-position: 16px 7%;
   }
    .MainContainer{
        /* background-position: 270px 7%; */
        background-position: 7px 7%;
        margin-top: 2rem;
    }
    .MainTitle{
        /* padding-top: 36%; */
        padding-top: 18%;
        font-size: 34px;
    }
    .MainDescription{
        /* font-size: 32px; */
        font-size: 30px;
        width: 100%;
    }
    .MainSubDescription{
        width: 100%;
        padding-bottom: 2%;

    }
}


@media screen and(min-width: 1023px){
.MainContainer{
    height: 100%;
}
}