.TestimonalMainsection{
    /* background-color: black; */
    /*background-color: #637A9F; */ 
    /* background-color: #378CE7; */
    background-color: #265073;
}
.TestimonialsTitle{
    text-align: center;
    color: white;
    margin-bottom: 3rem;
}
.TestimonialFirst,.TestimonialSecond,.TestimonialThird{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5% 0%;
}
@media screen and (min-width: 767px){
.TestimonialsFirstDescription,.TestimonialsThirdDescription{
    padding-left:10%;
}
.TestimonialsSecondDescription{
    padding-right:10%;
}
}
.TestimonialsFirstDescription,.TestimonialsSecondDescription,.TestimonialsThirdDescription{
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
    color: white;
    line-height: 26px;
}

.TestimonialFirst img ,.TestimonialSecond img, .TestimonialThird img{
    border : 5px solid white;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .TestimonialFirst,.TestimonialThird{
        display: flex;
        flex-direction: column;
    }
    .TestimonialSecond{
        display: flex;
        flex-direction: column-reverse;
    }
    .TestimonialsFirstDescription,.TestimonialsSecondDescription,.TestimonialsThirdDescription{
        padding-top: 3%;
    }
}