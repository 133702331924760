.GallerySection{
    padding-top: 8rem;
    /* background-color: #000000; */
    /*background-color: #637A9F; */ 
    /* background-color: #378CE7; */
    background-color: #265073;
}
.GalleryTitle{
    text-align: center;
    color: white;
    margin-bottom: 4rem;
}
.FrontCard{
    width:100%;
    height:100%;
}
.GalleryCard{
    width: 100%;
    height: 100%;
}
.GalleryRow img{
    border: 5px solid #ffffff;
}