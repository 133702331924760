.underConstructionClass{
    font-family: 'Oswald', sans-serif;
    text-align: center;
    color: white;
    background-color: black;
}
.underConstructionTitle{
    height: 100vh;
    text-align: center;
    align-items: center;
    display: grid;
}
